<template>
    <div class="nHome-detail">
        <van-nav-bar :title="$t('news.myorder')" class="nav-bar">
        </van-nav-bar>
        <div>
            <van-tabs v-model="active">
                <van-tab :title="item.name" v-for="(item, index) in order_tab" :key="index">

                    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
                        :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
                        <van-list v-model="loading" :finished="finished" :offset="50" :immediate-check="false"
                            :finished-text="$t('video.no_more')" @load="onLoad">
                            <div class="movie-list-n-item" v-for="(v,key) in movielist_1" :key="key"></div>
                        </van-list>

                    </van-pull-refresh>
                </van-tab>

            </van-tabs>

        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs'
export default {
    data() {
        return {
            dayjs,
            
            loading: false,
            finished: false,
            isLoading: true,
            movielist_1:[],
            page:1,
            active: 0,
            order_tab: [
                {
                    id: 0,
                    name:this.$t('news.all')
                },
                {
                    id: 0,
                    name: this.$t('news.daijiedai')
                },
                {
                    id: 0,
                    name: this.$t('news.jinxingzhong')
                },
                {
                    id: 0,
                    name: this.$t('news.daipingjia')
                },
                {
                    id: 0,
                    name: this.$t('news.yiwancheng')
                },
                {
                    id: 0,
                    name:this.$t('news.shouhou')
                },
            ]
        };
    },
    created() {
        this.$http({
            method: 'get',
            url: 'xuanfeidata',
            data: { id: this.$route.query.id }
        }).then(res => {
            console.log(res.data)
            this.info = res.data
        })

    },

    mounted() {
    },
    methods: {
        
    onRefresh() {
      setTimeout(() => {
        this.page = 1
        this.getNhomeList();
        this.isLoading = false;
        this.$toast(this.$t('reservation.refresh'));
      }, 500);
    },
    getNhomeList() {
      this.$http({
        method: 'get',
        url: 'xuanfeilist',
        data: { page: this.page },
      }).then(res => {
        this.loading = false;
        // if (this.page == 1) {
        //   this.movielist_1 = res.data;
        // } else {

        //   this.movielist_1 = this.movielist_1.concat(res.data);
        // }
        if (res.data.length == 0) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        this.count = res.data.count;
        this.page++;
      })

    },
    onLoad() {
      this.loading = true;
      this.getNhomeList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.movielist_1 = [];
          this.refreshing = false;
        }
        // this.loading = false;
        if (this.movielist_1.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
        back() {
            if (this.url) {

                this.$router.replace({ path: '/Mine' })
            } else {
                window.history.back();
            }
        },

    }
};
</script>

<style scoped lang="less">
.nHome-detail {
    background: #f5f5f5;
    height: 100vh;
    overflow: auto;
}

::v-deep .van-tab {
    font-size: 30px;
    line-height: 100px;
    font-weight: bold;
}

::v-deep .van-tabs__line {
    background-color: #F676B0;
}

::v-deep .van-tabs--line .van-tabs__wrap {
    height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
    padding: 0 23px;
}

::v-deep .van-tab--active .van-tab__text {
    color: #F676B0;
}
</style>