<template>
  <div class="nHome-detail" v-if="info">
    <van-nav-bar :title="info.xuanfei_name" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div style="height: 10px;"></div>

    <!-- swiper1 -->
    <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop" style="position: relative;">
      <swiper-slide class="slide-1" style="text-align: center;" v-for="(item, index) in info.img_url" :key="index">
        <van-image :src="item" style="width: 60%; margin: auto;" :show-loading="false" fit="contain"
          @click="openImg(index)" />
      </swiper-slide>
    </swiper>
    <!-- swiper2 Thumbs -->
    <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
      <swiper-slide class="slide-1" style="width: 25%;" v-for="(item, index) in info.img_url" :key="index">
        <van-image :src="item" :show-loading="false" radius="6" fit="contain" />
      </swiper-slide>
    </swiper>
    <div class="detail_content">
      <div class="product-description">
      <div class="title1">{{$t('xuanfei.summary')}}:</div>
      <p>{{info.content}}</p>
    </div>
    <div class="">
      <div class="product-detail">
        <div class="products-title">
          <h2 class="h4">{{$t('xuanfei.kieuAnh')}}</h2>
        </div>
        <div class="product-attribute product-single">
          <div>
            <div class="title1">{{$t('xuanfei.information')}}</div>
            <div class="nav-thumbs nav mb-3" id="information_post">
              <!-- <div class="information-box">
                <div class="title">Số điện thoại:</div>
                <div class="sdt">0977845013</div>
              </div> -->
              <div class="information-box">
                <div class="title">{{$t('xuanfei.Passenger_price')}}:</div>
                <div>{{info.price}}</div>
              </div>
              <!-- <div class="information-box">
                <div class="title">Giá phòng:</div>
                <div>100 - 120k/2h đầu</div>
              </div> -->
              <div class="information-box">
                <div class="title">{{$t('xuanfei.birth')}}:</div>
                <div>{{info.birthday}}</div>
              </div>
              <div class="information-box">
                <div class="title">{{$t('xuanfei.address')}}:</div>
                <div>{{info.address}}</div>
              </div>
            </div>
          </div>
          <div>
            <div class="title1">{{$t('xuanfei.identification')}}</div>
            <div class="nav-thumbs nav mb-3" id="size_post">
              <div class="information-box">
                <div class="title">{{$t('xuanfei.height')}}:</div>
                <div>{{info.height}}</div>
              </div>
              <div class="information-box">
                <div class="title">{{$t('xuanfei.weight')}}:</div>
                <div>{{info.weight}}</div>
              </div>
              <div class="information-box">
                <div class="title">{{$t('xuanfei.round1')}}:</div>
                <div>{{info.one}}</div>
              </div>
              <div class="information-box">
                <div class="title">{{$t('xuanfei.round2')}}:</div>
                <div>{{info.two}}</div>
              </div>
              <div class="information-box">
                <div class="title">{{$t('xuanfei.round3')}}:</div>
                <div>{{info.three}}</div>
              </div>
            </div>
          </div>
          <div>
            <div class="title1">{{$t('xuanfei.informationServices')}}</div>
            <div class="nav-thumbs nav mb-3" id="service_post">
              <div class="information-box">
                <div class="title">{{$t('xuanfei.Service')}}:</div>
                <div>{{ info.service }}</div>
              </div>
              <!-- <div class="information-box">
                <div class="title">Phân loại:</div>
                <div>Hàng Vip</div>
              </div>
              <div class="information-box">
                <div class="title">Giờ phục vụ:</div>
                <div>30 - 40 phút</div>
              </div>
              <div class="information-box">
                <div class="title">Giờ làm việc:</div>
                <div>8h sáng đến 1h đêm ( Có qua đêm )</div>
              </div>
              <div class="information-box">
                <div class="title">Cam kết:</div>
                <div class="title2"><span>Không ảnh ảo, không tráo hàng, đầy đủ dịch vụ</span></div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
   
     
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { ImagePreview } from 'vant';

import dayjs from 'dayjs'
export default {
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOptionTop: {
        loop: true,
        loopedSlides: 50, // looped slides should be the same
        spaceBetween: 10
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 50, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        slideToClickedSlide: true
      },
      btnLogin: false,
      info: null,
      commentlist: [],
      dayjs
    };
  },
  created() {
    this.$http({
      method: 'get',
      url: 'xuanfeidata',
      data: { id: this.$route.query.id }
    }).then(res => {
      console.log(res.data)
      this.info = res.data

      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.swiper
        const swiperThumbs = this.$refs.swiperThumbs.swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
      })
    })

  },

  mounted() {
  },
  methods: {
    openImg(index) {
      let that = this;
      ImagePreview({
        images: that.info.img_url,
        startPosition: index,
      });
    },
    back() {
      if (this.url) {

        this.$router.replace({ path: '/Mine' })
      } else {
        window.history.back();
      }
    },

  }
};
</script>

<style scoped lang="less">
.nHome-detail {
  background: #f2f2f5;
  height: 100vh;
  overflow: auto;
  color: #000;
}


.thumb-example {
  height: 480px;
}

.swiper {}


.swiper-slide {
  background-size: cover;
  background-position: center;
}

.gallery-top {
  height: 650px;
}

.gallery-thumbs {
  height: 300px;
  box-sizing: border-box;
  padding: 30px 0;
  overflow: hidden;
}

.gallery-thumbs .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}

.information-box {
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
    color: #7d879c;
  .title{
    
    font-size: 32px;
  }
}
.products-title {

  .h4{
    font-size: 40px;
    font-weight: bold;
  }
}
.product-description{
  label{
    color: 32px;
    font-weight: bold;
  }
  p{
    color: #7d879c;
    line-height: 1.4;
  }
}
.title1{
  margin: 20px 0;
}
.nav-thumbs{
  margin-bottom: 30px;
}
.detail_content{
  padding: 30px;
}
.sdt {
    font-weight: bold;
    color: #ff6f61;
    font-size: 40px;
}
</style>
