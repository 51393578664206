<template>
    <div class="nHome-detail">
        <van-nav-bar :title="$t('news.fuwudizhi')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>

        <div >
            <van-form @submit="onSubmit">
                <van-cell is-link>
                    <template #title>
                        <span class="custom-title">{{ $t('news.fuwudizhi') }}({{ $t('news.weizhi') }})</span>
                    </template>
                    <div class="cell_value_user" @click="$router.push({path:'/txgSearch'})">
                        {{ $t('news.dianjixuanze') }}
                    </div>
                </van-cell>
                <van-field v-model="from.menpaihao1" :name="$t('news.menpaihao')" :label="$t('news.menpaihao')" :placeholder="$t('news.menpaihao')"
                   />
                <van-field v-model="from.lixnairen1"  :name="$t('news.lixnairen')" :label="$t('news.lixnairen')" :placeholder="$t('news.lixnairen')"
                  />
                <van-field name="radio" :label="$t('news.xingbie')">
                    <template #input>
                        <van-radio-group v-model="from.xingbie1" direction="horizontal">
                            <van-radio name="1" checked-color="#f676b0">先生</van-radio>
                            <van-radio name="2" checked-color="#f676b0">女士</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field v-model="from.lianxidianhua1"  :name="$t('news.lianxidianhua')" :label="$t('news.lianxidianhua')" :placeholder="$t('news.lianxidianhua')"
                    />
                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit">保存</van-button>
                </div>
            </van-form>

        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs'
export default {
    data() {
        return {
            dayjs,
            from:{
                menpaihao1:'',
            lixnairen1:'',
            xingbie1:1,
            lianxidianhua1:''
            }
        };
    },
    created() {
        this.$http({
            method: 'get',
            url: 'xuanfeidata',
            data: { id: this.$route.query.id }
        }).then(res => {
            console.log(res.data)
            this.info = res.data
        })

    },

    mounted() {
    },
    methods: {
        
    onSubmit(values) {
      console.log('submit', values);
    },
        back() {
            if (this.url) {

                this.$router.replace({ path: '/Mine' })
            } else {
                window.history.back();
            }
        },

    }
};
</script>

<style scoped lang="less">
.addr_list {
    display: flex;
    align-items: center;
    background: #fff;
    margin: 10px 30px;
    padding: 30px;
    border-radius: 20px;
}

.addr_list_username {
    font-size: 28px;
}

.addr_list_useraddr {
    font-size: 24px;
}

.nHome-detail {
    background: #f5f5f5;
    height: 100vh;
    overflow: auto;
}


.cell_value {
    color: #f676b0;
}

.van-cell__title,
.van-cell__value {
    line-height: 1;
}


.cell_value_user_img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 20px;
}

.cell_value_user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // line-height: 0;
}

.custom-title {
    font-size: 28px;
    display: flex;
    font-weight: bold;
    align-items: center;
}

.van-cell {
    font-size: 28px;
    padding: 40px 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.van-cell__right-icon {
    font-size: 30px;
}

/deep/.van-radio__icon {
  font-size: 30px !important;
}
/deep/.van-radio__label {
  margin-left: 25px !important;
  font-size: 28px !important;
}

/deep/.van-radio {
  height: 65px !important;
}

.van-button {
    width: 100%;
    font-size: 28px;
    height: 2.125rem;
    border-radius: 6.1875rem !important;
    background: #f676b0;
    border-color: #f676b0;
}
</style>