<template>
    <div class="nHome-detail">
        <van-nav-bar :title="$t('news.fuwudizhi')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div style="height: 10px;"></div>
        <div>

            <div class="addr_list" @click="$router.push({path:'/txgSearch'})">
                <div style="width: 70%;">
                    <div class="addr_list_username">zhang  9177791</div>
                    <div class="addr_list_useraddr">9/9 Vibhavadi Rangsit Rd, Khwaeng Talat Bang Khen, Khet Lak Si,
                        Krung Thep
                        Maha Nakhon 10210(1403)</div>
                </div>
                <div style="flex: 1;"></div>
                <van-icon name="edit" size="26" style="margin-right: 4px;" />
                <van-icon name="delete-o" size="26" color="#e82c2f" />
            </div>
        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs'
export default {
    data() {
        return {
            dayjs
        };
    },
    created() {
        this.$http({
            method: 'get',
            url: 'xuanfeidata',
            data: { id: this.$route.query.id }
        }).then(res => {
            console.log(res.data)
            this.info = res.data
        })

    },

    mounted() {
    },
    methods: {
        back() {
            if (this.url) {

                this.$router.replace({ path: '/Mine' })
            } else {
                window.history.back();
            }
        },

    }
};
</script>

<style scoped lang="less">
.addr_list {
    display: flex;
    align-items: center;
    background: #fff;
    margin: 10px 30px;
    padding: 30px;
    border-radius: 20px;
}

.addr_list_username {
    font-size: 28px;
}

.addr_list_useraddr {
    font-size: 24px;
}
.nHome-detail{
    background: #f5f5f5;
    height: 100vh;
    overflow: auto;
}
</style>