<template>
    <div class="appointment-detail" v-if="info">
        <van-nav-bar :title="$t('news.yuyuexiadan')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div style="height: 10px;"></div>
        
        <div style="margin-bottom: 10px;">
            <van-cell class="bh" is-link  @click="$router.push({path:'/addrList'})">
                <template #title>
                    <span class="custom-title">
                        {{ $t('news.xuanzedizhi') }} </span>
                </template>
                <template #label>
                    <span class="custom-lab" style="color: #909399;">{{ $t('news.tianxindizhi') }}</span>
                </template>

            </van-cell>
            <div class="server_addr_time">
                <van-icon name="clock-o" style="margin-right: 4px;" /> {{ $t('news.fuwushichang') }} <div style="flex: 1;"></div> 1{{$t('news.xiaoshi')}}(1 hour)
            </div>

        </div>
        <div class="appointment_info">
            <img class="appointment_info_img" src="/img/product_img.png" alt="">
            <div>
                <div class="appointment_info_time">1{{$t('news.xiaoshi')}}(1hour)</div>
                <div class="appointment_info_num">
                    <img class="money_icon" src="/img/money_icon.png" alt="">
                    500
                </div>
                <div class="appointment_info_txt">{{ $t('news.daodian60') }}</div>
            </div>
        </div>
        <div style="margin-top: 10px;">
            <van-cell>
                <template #title>
                    <span class="custom-title">{{ $t('news.tianxin') }}</span>
                </template>
                <div class="cell_value_user">
                    TEST
                    <img class="cell_value_user_img" src="/img/money_icon.png" alt="">
                </div>

            </van-cell>
            <van-cell is-link>
                <template #title>
                    <span class="custom-title">{{ $t('news.yuyueshijian') }}</span>
                </template>
                <div class="cell_value" @click="time_show = true">{{ time_type_col[time_type]}} {{ time_date }}</div>
            </van-cell>
            <van-cell is-link>
                <template #title>
                    <span class="custom-title">{{ $t('news.fuwufangshi') }}</span>
                </template>
                <div class="cell_value" @click="server_show = true">{{ server_columns[server_columns_checked]?server_columns[server_columns_checked]:$t('news.qingxuanze') }} </div>
            </van-cell>

        </div>

        <div style="margin-top: 10px;">
            <van-cell>
                <template #title>
                    <span class="custom-title">{{ $t('news.beizhu') }}</span>
                </template>
            </van-cell>
            <van-field v-model="message" rows="2" autosize type="textarea" :placeholder="$t('news.beizhu_pla')"
                show-word-limit />
        </div>
        <div style="margin-top: 10px;">
            
            <van-cell>
                <template #title>
                    <span class="custom-title">{{ $t('news.chuxingfeiyong') }}</span>
                </template>
            </van-cell>
            
            <van-cell>
                <template #title>
                    <span class="custom-title">{{ $t('news.yugufeiyong') }}</span>
                </template>
                <div class="cell_value">0</div>
            </van-cell>
        </div>


        <div style="margin-top: 10px;">
            <van-cell class="bh">
                <template #title>
                    <span class="custom-title">
                        <img class="bh_img" src="/img/bh.png" alt="">
                         {{ $t('news.haomabaohu') }}</span>
                </template>
                <template #label>
                    <span class="custom-lab">{{ $t('news.haomabaohu') }}</span>
                </template>

            </van-cell>

        </div>

        <van-popup v-model="time_show" position="bottom">

            <van-tabs v-model="today_date_actvie">
            <van-tab :title="$t('news.jintian')" >
                <div class="date_list" v-for="(item,index) in today_date_list" @click="checked_time(1,item)" :key="index">{{ item }}</div>
            </van-tab>
            <van-tab :title="$t('news.mintian')">
                <div class="date_list" v-for="(item,index) in tomorrow_date_list" :key="index" @click="checked_time(2,item)">{{ item }}</div></van-tab>
            <van-tab :title="$t('news.houtian')">
                <div class="date_list" v-for="(item,index) in tomorrow_date_list" :key="index" @click="checked_time(3,item)">{{ item }}</div></van-tab>
            </van-tabs>
            
        </van-popup>



        <van-popup v-model="server_show" position="bottom">

            <div v-for="(item, index) in server_columns" :key="index" class="server_columns" @click="server_columns_checked = index;server_show = false">
                {{ item }}
                <div style="flex: 1;"></div>
                <img class="server_columns_checked_img" v-if="server_columns_checked == index" src="/img/gou.svg" alt="">
                <img class="server_columns_checked_img" v-else src="/img/nogou.png" alt="">
            </div>
        </van-popup>
        <div class="projectDetail_footer">
            <img class="projectDetail_footer_ico" src="/img/money_icon.png" alt=""> <span
                style="color: #e82b2f;margin-right: 10px;">500</span> {{ $t('news.zhifufeiyong') }}
            <div style="flex: 1;"></div>
            <div class="projectDetail_footer_btn" @click="$router.push({ path: '/appointment?id=' + $route.query.id })">
                {{ $t('news.lijixiadan') }}
            </div>
        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs'
export default {
    components: {
    },
    data() {
        return {
            info: {},
            dayjs,
            time_show: false,
            time_show_index: 0,
            time_columns: [],
            server_show: false,
            server_show_index: 0,
            server_columns: [this.$t('news.daojiafuwu'),this.$t('news.daodianfuwu')],
            time_date:'',
            time_type:1,
            server_columns_checked:null,
            time_type_col:{
                1:this.$t('news.jintian'),
                2:this.$t('news.mintian'),
                3:this.$t('news.houtian'),
            },
            message:'',
            today_date_actvie:0,
            today_date_list:[],
            tomorrow_date_list:["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00"]
        };
    },
    created() {
        this.$http({
            method: 'get',
            url: 'xuanfeidata',
            data: { id: this.$route.query.id }
        }).then(res => {
            console.log(res.data)
            this.info = res.data

        })

    },

    mounted() {
        // let data = []
        // let arrTime = []
        let date1 = new Date();
        let nowHour = date1.getHours() + 1;
        for (let i = 0; i <= 23 - nowHour; i++) {
            this.today_date_list.unshift(23 - i + ":00");
        }
        if(this.today_date_list[0]){

            this.time_date = this.today_date_list[0]
        }else{
            this.time_type = 2
            this.today_date_actvie = 1
            this.time_date = this.tomorrow_date_list[0]

        }
    },
    methods: {
        checked_time(time_type,time_date){
            this.time_type = time_type
            this.time_date = time_date
            this.time_show = false
        },
        time_onConfirm() {
            this.time_onCancel()
        },
        time_onCancel() {
            this.time_show = false
        },
        time_onChange() {
            this.time_onCancel()

        },
        server_onConfirm() {
            this.server_onCancel()
        },
        server_onCancel() {
            this.server_show = false
        },
        server_onChange() {
            this.server_onCancel()

        },
        back() {
            if (this.url) {

                this.$router.replace({ path: '/Mine' })
            } else {
                window.history.back();
            }
        },

    }
};
</script>

<style scoped lang="less">
.appointment-detail {

    background: #f2f2f5;
    height: 100vh;
    padding-bottom: 200px;
    overflow: auto;
}

.appointment_info {
    padding: 30px;
    background: #fff;
    display: flex;
    // justify-content: center;
    align-items: center;
}

.appointment_info_img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-right: 30px;
}

.appointment_info_time {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 10px;
}

.appointment_info_num {
    font-size: 24px;
    color: #ff8400;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.money_icon {
    margin-right: 10px;
}

.appointment_info_txt {
    font-size: 32px;
}


.projectDetail_footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    // height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    background: #fff;
    height: 120px;
    overflow: hidden;
}

.projectDetail_footer_ico {
    width: 60px;
    height: 60px;
    margin-right: 10px;
}

.projectDetail_footer_btn {
    background: #f676b0 !important;
    font-size: 28px;
    color: #fff;
    padding: 20px 30px;
    // border-radius: 20px;
}

.van-cell {
    font-size: 28px;
    padding: 40px 30px;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.van-cell__right-icon {
    font-size: 30px;
}

.custom-title {
    font-size: 28px;
    display: flex;
    font-weight: bold;
    align-items: center;
}

.custom-lab {
    font-size: 28px;
    color: #04cb94;
    font-weight: bold;
}

.bh {

    ::v-deep .van-cell__label {
        padding-top: 30px;

    }

    .bh_img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
    }
}

.cell_value {
    color: #f676b0;
}

.van-cell__title,
.van-cell__value {
    line-height: 1;
}


.cell_value_user_img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 20px;
}

.cell_value_user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    line-height: 0;
}

::v-deep .van-picker__toolbar {
    height: 80px;
}

::v-deep .van-picker__cancel,
.van-picker__confirm {
    padding: 0 20px;
    font-size: 20px;
}

::v-deep .van-picker-column {
    font-size: 40px;
}

/deep/.van-picker__confirm,
/deep/.van-picker__cancel {
    font-size: 36px;
}

::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #F676B0;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.date_list{
    display: inline-block;
    // width: 120px;
    text-align: center;
    background: #f5f5f5;
    font-size: 26px;
    padding: 20px 30px;
    margin: 20px 30px;
    border-radius: 40px;
}
.server_columns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:  30px;
}
.server_columns_checked_img{
    width: 40px;
    height: 40px;
}
.server_addr_time{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px 30px;
    color: #fff;
    background: #F676B0;
    border-radius: 0 0 20px 20px;
}
</style>